@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
}

body {
  margin: 0;
  background: #6dd5fa;
}

body:after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(to bottom, #2980b9, #6dd5fa, #ffffff);
}